import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.4.31828" />
      <meta name="build-date" content="2025-03-25T21:58:07Z" />
    </Head>
  );
};

export default Versioning;
